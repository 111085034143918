import { createReducer, on } from '@ngrx/store';
import { Sort } from '@src/app/shared/models/types/sort.type';

import { setSortKey } from './sort.actions';
import { ISortState } from './sort-state.interface';

export const sortFK = 'sortFK';

export const initialState: ISortState = {
  sortKey: Sort.Priority,
};

export const sortReducer = createReducer(
  initialState,

  on(
    setSortKey,
    (state, action): ISortState => ({
      ...state,
      sortKey: action.sortKey,
    })
  )
);
