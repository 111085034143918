import { Pipe, PipeTransform } from '@angular/core';

import { NUMERALS_ADAPTIVE_TEXT } from '../../models/constants/numerals-adaptive-text';
import { Languages } from '../../models/enums/languages.enum';

@Pipe({
  name: 'numeralsAdaptiveText',
  standalone: true,
})
export class NumeralsAdaptiveTextPipe implements PipeTransform {
  public transform(num: number, key: string, language: string): string {
    let adaptiveText = `${num} `;

    switch (language) {
      case Languages.DE:
      case Languages.EN:
        adaptiveText =
          adaptiveText +
          this.selectLatinText(num, NUMERALS_ADAPTIVE_TEXT[key][language]);
        break;
      case Languages.RU:
        adaptiveText =
          adaptiveText +
          this.selectCyrillicText(num, NUMERALS_ADAPTIVE_TEXT[key][language]);
        break;
    }

    return adaptiveText;
  }

  private selectCyrillicText(num: number, localText: string[]): string {
    num = Math.abs(num);

    if (Number.isInteger(num)) {
      const cases = [2, 0, 1, 1, 1, 2];

      return localText[
        num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]
      ];
    } else {
      return localText[1];
    }
  }

  private selectLatinText(num: number, localText: string[]): string {
    if (num === 1) {
      return localText[0];
    } else {
      return localText[1];
    }
  }
}
