<div class="pathology">
  <div class="pathology__header">
    <div class="header">
      <a
        class="title"
        [routerLink]="[
          '/',
          isPathology ? routingMap.pathology : routingMap.anatomy,
          categoryUuid,
          collectionItem.uuid,
        ]">
        <ng-content select="[title]"></ng-content>
      </a>
      @if (countOfItems) {
        <span class="num-model">
          ({{ countOfItems | numeralsAdaptiveText: 'model' : currentLanguage }})
        </span>
      }
    </div>
    <div class="icons">
      @if (hasArticle) {
        <app-pathology-article
          class="icon"
          [pathologyUuid]="collectionItem.uuid"
          [isCard]="false">
        </app-pathology-article>

        <div class="divider"></div>
      }

      <app-link-share
        class="icon"
        [linkType]="linkType.Pathology"
        [linkShareParams]="{
          categoryUuid,
          pathologyUuid: collectionItem.uuid,
          isPathology,
        }"></app-link-share>
      <app-collection-status
        class="icon"
        [collectionItem]="collectionItem"></app-collection-status>
    </div>
  </div>
  <div class="pathology__content">
    <ng-content select="[cards]"></ng-content>
  </div>
</div>
