import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  LOCALE_ID,
} from '@angular/core';
import { RouterLink, RouterModule } from '@angular/router';

import { ROUTING_MAP } from '../../models/constants/routing-map';
import { ICollectionItem } from '../../models/interfaces/collection-item.interface';
import { NumeralsAdaptiveTextPipe } from '../../pipes/numerals-adaptive-text/numerals-adaptive-text.pipe';
import { CollectionStatusComponent } from '../collection-status/collection-status.component';
import {
  LinkShareComponent,
  LinkType,
} from '../link-share/link-share.component';
import { PathologyArticleComponent } from '../pathology-article/pathology-article.component';

@Component({
  selector: 'app-pathology-card-list',
  templateUrl: './pathology-card-list.component.html',
  styleUrls: ['./pathology-card-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CollectionStatusComponent,
    LinkShareComponent,
    NumeralsAdaptiveTextPipe,
    RouterLink,
    RouterModule,
    PathologyArticleComponent,
  ],
})
export class PathologyCardListComponent {
  @Input() public categoryUuid: string;

  @Input() public countOfItems = 0;

  @Input() public collectionItem!: ICollectionItem;

  @Input() public isPathology: boolean = false;

  @Input() public hasArticle: string;

  public linkType = LinkType;

  public routingMap = ROUTING_MAP;

  constructor(@Inject(LOCALE_ID) public currentLanguage: string) {}
}
