import { Languages } from '../enums/languages.enum';

export const NUMERALS_ADAPTIVE_TEXT: Record<
  string,
  Record<Languages, string[]>
> = {
  model: {
    de: ['Modell', 'Modelle'],
    en: ['model', 'models'],
    ru: ['модель', 'модели', 'моделей'],
  },
};
