import { createFeatureSelector, createSelector } from '@ngrx/store';

import { sortFK } from './sort.reducer';
import { ISortState } from './sort-state.interface';

const selectFeature = createFeatureSelector<ISortState>(sortFK);

export const selectSortKey = createSelector(
  selectFeature,
  (state: ISortState) => state.sortKey
);
