@if (isCard) {
  <div
    class="article"
    [class.phone]="mobileDeviceService.isMobileDevice"
    (click)="openArticle()">
    <div class="wrapper">
      <div class="article__mark" [class.collection-mark]="isCollection">
        <ng-container *ngTemplateOutlet="markRef"></ng-container>
      </div>
      <div class="article__content">
        <img src="assets/svg/article-card.svg" class="icon" />
        <span class="title">
          <ng-container *ngTemplateOutlet="titleRef"> </ng-container>
        </span>
      </div>
    </div>
  </div>
} @else {
  <svg-icon
    class="open-icon"
    key="open-article"
    (click)="openArticle()"></svg-icon>
}
