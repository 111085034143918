import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setSortKey } from '@store/sort/sort.actions';
import { selectSortKey } from '@store/sort/sort.selectors';
import { filter, take } from 'rxjs';

import { ARTICLE_VIEW_URL_REGEXP } from '@app/shared/models/constants/regexp';
import { Sort } from '../../models/types/sort.type';
import { SwitcherComponent } from '../switcher/switcher.component';

@Component({
  selector: 'app-sort-switcher',
  templateUrl: './sort-switcher.component.html',
  styleUrls: ['./sort-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, SwitcherComponent],
})
export class SortSwitcherComponent implements OnDestroy {
  public sortKeysList: Sort[] = Object.values(Sort);

  public sortKey$ = this.store.select(selectSortKey);

  public sortLocalization = {
    priority: $localize`По умолчанию`,
    'a-to-z': $localize`От А до Я`,
    'z-to-a': $localize`От Я до А`,
  };

  @ViewChild('switcher') private switcher: SwitcherComponent;

  constructor(
    private router: Router,
    private store: Store
  ) {}

  public ngOnDestroy(): void {
    this.saveSortState();
  }

  public sortBy(sort: Sort): void {
    this.store.dispatch(setSortKey({ sortKey: sort }));
    this.switcher.closeList();
  }

  private saveSortState(): void {
    // TODO: check why takeUntilDestroyed not used
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(event => {
        const e = event as NavigationEnd;

        if (!ARTICLE_VIEW_URL_REGEXP.test(e.url)) {
          this.store.dispatch(setSortKey({ sortKey: Sort.Priority }));
        }
      });
  }
}
