import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  DestroyRef,
  Input,
  OnDestroy,
  TemplateRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { MobileDeviceService } from '@app/shared/services/mobile-device.service';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { Store } from '@ngrx/store';
import {
  getArticle,
  setActiveArticlesCategoryUuid,
  unsetArticle,
} from '@store/articles/articles.actions';
import { selectArticle } from '@store/articles/articles.selectors';
import { ROUTING_MAP } from '../../models/constants/routing-map';

@Component({
  selector: 'app-pathology-article',
  templateUrl: './pathology-article.component.html',
  styleUrls: ['./pathology-article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet, SvgIconComponent],
})
export class PathologyArticleComponent implements OnDestroy {
  @Input() public pathologyUuid: string;

  @Input() public isCollection: boolean = false;

  @Input() public isCard: boolean = true;

  @ContentChild('title') public titleRef!: TemplateRef<any>;

  @ContentChild('mark') public markRef!: TemplateRef<any>;

  constructor(
    public mobileDeviceService: MobileDeviceService,
    private destroyRef: DestroyRef,
    private router: Router,
    private store: Store
  ) {}

  public openArticle(): void {
    this.store
      .select(selectArticle)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(article => {
        if (article) {
          this.store.dispatch(
            setActiveArticlesCategoryUuid({
              categoryUuid: article.categoryUuid,
            })
          );
          this.router.navigate([
            ROUTING_MAP.articles,
            article.categoryUuid,
            article.pathologyUuid,
          ]);
        }
      });

    this.store.dispatch(getArticle({ pathologyUuid: this.pathologyUuid }));
  }

  public ngOnDestroy(): void {
    this.store.dispatch(unsetArticle());
  }
}
