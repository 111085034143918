<app-switcher #switcher>
  <span title>{{ sortLocalization[sortKey$ | async] }}</span>
  <div panel class="select-panel">
    @for (sortKeyItem of sortKeysList; track sortKeyItem) {
      @if (sortKeyItem !== (sortKey$ | async)) {
        <div class="option" (click)="sortBy(sortKeyItem)">
          {{ sortLocalization[sortKeyItem] }}
        </div>
      }
    }
  </div>
</app-switcher>
